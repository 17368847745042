.App {
  text-align: center;
  margin-top: 20px;
  padding: 15px;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
}

.App-header {
  margin-bottom: 20px;
}

.logo {
  max-height: 65px;
  width: 100%;
}

h1 {
  font-size: 26px;
}

h2 {
  font-size: 18px;
  font-weight: 400;
}

.mirrors {
  margin: 75px 0;
}

.mirror {
  font-size: 18px;
  margin-bottom: 10px;
}

.mirror a {
  color: #dcb43f;
  text-decoration: none;
}

.mirror a:hover {
  text-decoration: underline;
}

.description {
  background: #3a3c66;
  padding: 15px;
  text-align: left;
  margin-top: 20px;
}

.description h3 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #ccc;
}

.description p {
  color: #eeeeeea1;
  font-size: 14px;
  margin: 0;
}

@media(max-width: 767px) {
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 14px;
  }
}
